import { SWITCH, DROPDOWN, DROPDOWN_CHECKBOX, YES } from '../../../constants';
import translator from '../../../../../../services/translator';
import {capitalizeString} from '../../../../../../utils/stringUtils';

const { translate: t } = translator;

const apiValueGetter = (featureEntitlements = {}, config, editable) => {
  let values = [];
  if (`${featureEntitlements[`${config.value}Edit`]}` === YES) {
    values = ['View', 'Edit'];
  } else if (`${featureEntitlements[`${config.value}View`]}` === YES) {
    values = ['View'];
  }
  return editable ? values : values.includes('Edit') ? capitalizeString(t("tkEdit")) : values.includes('View') ? t('tkView') : capitalizeString(t("tkNo"));
};

export const featureConfig = {
  core: [
    {
      id: 1,
      label: 'tkContactUs',
      value: 'contactUs',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 2,
      label: 'tkFAQAndGlossary',
      value: 'faq',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 3,
      label: 'tkMessageCenter',
      value: 'messageCenter',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
  ],
  accounts: [
    {
      id: 4,
      label: 'tkAccountDetails',
      value: 'accountDetails',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 5,
      label: 'tkActivityTransactions',
      value: 'activityTransaction',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 6,
      label: 'tkInvestmentPolicy',
      value: 'investmentPolicy',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 7,
      label: 'tkYourPortfolio',
      value: 'portfolio',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 8,
      label: 'tkReporting',
      value: 'reporting',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
  ],
  funds: [
    {
      id: 9,
      label: 'tkFundFinder',
      value: 'fundFinder',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 10,
      label: 'tkFundTracker',
      value: 'fundTracker',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 11,
      label: 'tkGSAMFloatingNAVCalc',
      value: 'navCal',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 12,
      label: 'tkNavTransparencyInsights',
      value: 'transpInsight',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 13,
      label: 'tkOrganizationRebates',
      value: 'rebate',
      editableFieldType: SWITCH,
      showNAIfNull: true,
      isVisibleOnlyForAdmin: true
    }
  ],
  trading: [
    {
      id: 13,
      label: 'tkTradeApproval',
      value: 'tradeApproval',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 14,
      label: 'tkTradeBlotter',
      value: 'tradeBlotter',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 15,
      label: 'tkTradeImport',
      value: 'tradeImport',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    }    
  ],
  coreReports: [
    {
      id: 16,
      label: 'tkAccountBalances',
      value: 'reportingAccountBalance',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 17,
      label: 'tkCurrentTradeStatus',
      value: 'reportingCurrTradeStatus',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 18,
      label: 'tkCurrentTradeTotalsbyFund',
      value: 'reportingCurrTradeTotalByFund',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 19,
      label: 'tkFundBalances',
      value: 'reportingFundBalance',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 20,
      label: 'tkFundRatesandFactors',
      value: 'fundRatesAndFactors',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 21,
      label: 'tkGainLossOffshore',
      value: 'reportingGainLossOffshore',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 22,
      label: 'tkGainLoss',
      value: 'reportingGainLossOnshore',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 23,
      label: 'tkHistoricalTransaction',
      value: 'reportingHistoricalTxn',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 24,
      label: 'tkMonthlyDividendActivity',
      value: 'reportingMonthlyDividenedActivity',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 25,
      label: 'tkWireSettlement',
      value: 'reportingWireSettlement',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
  ],
  otherReports: [
    {
      id: 25,
      label: 'tkClassFee',
      value: 'reportingClassFee',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 26,
      label: 'tkDebitCreditAccountListing',
      value: 'reportingDebitCreditAccListing',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 27,
      label: 'tkDebitCreditDailyFeedSummaryActivity',
      value: 'reportingDebitCreditDailyFeedSummary',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 28,
      label: 'tkDebitCreditDailyTransactionActivity',
      value: 'reportingDebitCreditTxnActivity',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 29,
      label: 'tkDebitCreditSettlementSummary',
      value: 'reportingDebitCreditSettlementSummary',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 30,
      label: 'tkExPostCostsChargesSummary',
      value: 'reportingExPostCostAndChargesSummary',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 31,
      label: 'tkHistoricalTradeApprovalException',
      value: 'reportingHistoricalTradeApprovalException',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    {
      id: 32,
      label: 'tkMonthlyServiceFee',
      value: 'reportingMonthlyServiceFee',
      editableFieldType: SWITCH,
      showNAIfNull: true,
    },
    /*
    {
      id: 33,
      label: 'tkTradeWebActivity',
      value: 'tradeWebActivity',
    },
    */
  ],
  api: [
    {
      id: 33,
      label: 'tkApiAccounts',
      value: 'apiAccounts',
      editableFieldType: DROPDOWN_CHECKBOX,
      valueGetter: apiValueGetter
    },
    {
      id: 34,
      label: 'tkApiOrganizations',
      value: 'apiOrganizations',
      editableFieldType: DROPDOWN_CHECKBOX,
      valueGetter: apiValueGetter
    },
    {
      id: 35,
      label: 'tkApiProducts',
      value: 'apiProductsView',
      editableFieldType: DROPDOWN,
      dropdownKey: 'apiProducts',
    },
    {
      id: 36,
      label: 'tkApiTransactions',
      value: "apiTransactions",
      editableFieldType: DROPDOWN_CHECKBOX,
      valueGetter: apiValueGetter
    },       
    {
      id: 37,
      label: 'tkApiUsers',
      value: 'apiUsers',
      editableFieldType: DROPDOWN_CHECKBOX,
      valueGetter: apiValueGetter
    }
  ],
};
